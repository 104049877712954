import { Injectable } from '@angular/core';
import { FieldsService } from './fields.service';
import { TaxonomyService } from './taxonomy.service';
import { AuthRESTService } from './auth-rest.service';
import { AuthService } from './auth.service';
import { MenuRESTService } from './menu-rest.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class NgInitDataService {

  constructor(
    public _taxonomyService: TaxonomyService,
    public _fieldsService: FieldsService,
    private _authRESTService: AuthRESTService,
    private _authService: AuthService,
    private _menuRESTService: MenuRESTService,
    private _router: Router,
  ) { }

  init(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this._authRESTService.test_login().subscribe(response => {
        if (response['success'] == true && response['current_user']?.['field_license_agreed'] == true) {
          this._authService.current_user = response['current_user'];
          this._authService.csrf_token = response['csrf_token'];
          this._authService.logout_token = response['logout_token'];
          this._authService.studios = response['studios'];
          this._authService.set_user_authenticated(true);
          this._menuRESTService.getMenu();
          // If you want to navigate within your initializer, you can uncomment below:
        // this._router.navigate(['dashboard']);

        // Some more initialization functions.
        this._fieldsService.getInstructorData();
        this._taxonomyService.initTaxonomyTerms();

          resolve();
        } else {
          this._authService.set_user_authenticated(false);
          this._router.navigate(['auth/login']);

          // If you want the initialization to be considered a failure when the user isn't authenticated, you can reject here.
          // However, if you consider this to be a normal state and don't want an error state, you should call resolve() instead.
          resolve(); // or reject("User is not authenticated");
        }
      }, error => {
        console.error("Error during initialization:", error);
        reject(error);
      });
    });
  }

}
