import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';

import { map, switchMap, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DrupalRESTService implements OnInit {

  baseURL = environment.hostUrl;
  // csrfToken;

  _httpHeaders: HttpHeaders;
  _httpHeadersUpload: HttpHeaders;

  constructor(
    private http: HttpClient,
    private _authService: AuthService
  ) {
    //this._csrfToken()
  }

  httpHeaders() {
    // const csrfToken = localStorage.getItem('csrf_token');
    const csrfToken = this._authService.csrf_token;

    if (csrfToken) {
      this._httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken,
      });
    } else {
      this._httpHeaders = new HttpHeaders({
        'Content-Type': 'application/json',
      });
    }
  }

  ngOnInit(): void {
    // this._csrfToken();
  }

  /**
   * Utility to build our HTTPParams object.
   *
   * @param params
   * @returns HttpParams
   */
  private _httpParams(params?: { parameter: string, value: any }[]) {
    let httpParams = new HttpParams();

    // Add the studio reference to the main request
    if (this._authService.studios) {
      this._authService.studios.forEach(studio => {
        if (studio.id !== undefined && studio.id !== null) {
          httpParams = httpParams.append('field_studio_reference_target_id', studio.id.toString());
          httpParams = httpParams.append('field_studio_reference', studio.id.toString());
        }
      });
    }

    // Inject studio reference on the entire data before iterating over params
    if (params) {
      params.forEach((param) => {
        if (param.value && typeof param.value === 'object') {
          this.injectStudioReference(param.value);
        }
      });
    }

    // Existing logic
    if (typeof params !== 'undefined') {
      params.forEach((element) => {
        if (element.value !== undefined && element.value !== null) {
          httpParams = httpParams.append(element.parameter, element.value.toString());
        }
      });
    }

    return httpParams;
  }

  private needsStudioReference(obj: any): boolean {
    return obj && obj.hasOwnProperty('type') && obj['type'] === 'attendees';
  }

  private injectStudioReference(obj: any) {
    console.log("Checking object: ", obj);

    if (this.needsStudioReference(obj)) {
      console.log("Injecting studio reference into: ", obj);
      this._authService.studios.forEach(studio => {
        if (studio.id !== undefined && studio.id !== null) {
          obj['field_studio_reference_target_id'] = studio.id.toString();
          obj['field_studio_reference'] = studio.id.toString();
        }
      });
    }

    for (const key in obj) {
      if (obj[key] && typeof obj[key] === 'object') {
        if (Array.isArray(obj[key])) {
          for (const item of obj[key]) {
            this.injectStudioReference(item);
          }
        } else {
          this.injectStudioReference(obj[key]);
        }
      }
    }
  }

  public _csrfToken(): Observable<string> {
    let endpoint = "/session/token";
    let httpParams = this._httpParams();

    // Return an Observable which completes once the token is fetched and set
    return this.http.get(this.baseURL + endpoint, {
      headers: this._httpHeaders,
      params: httpParams,
      responseType: 'text' as 'json'  // Cast to 'json' to satisfy TypeScript's type expectations
    }).pipe(
      tap((token: string) => {
        this._authService.csrf_token = token;
        this.httpHeaders(); // this method updates the headers with the new token
      })
    );
  }

  /**
   * Standard HTTP GET request to Drupal.
   *
   * @param endpoint
   * @param params
   * @returns Observable
   */
  httpGET(endpoint: string, params?: { parameter: string, value: string }[]) {
    // Get HTTPParams object
    let httpParams = this._httpParams(params);

    return this.http.get(this.baseURL + endpoint, {
      headers: this._httpHeaders,
      params: httpParams,
      withCredentials: true
    })
      .pipe(
        map(data => {
          return data;
        },
          (error: string) => this.logError(error)
        )
      )
  }

  /**
   * Standard HTTP POST to Drupal.
   *
   * @param endpoint
   * @param body
   * @param params
   * @returns Observable
   */
  httpPOST(endpoint: string, body: any, params?: { parameter: string, value: string }[]): Observable<any> {
    // Get HTTPParams object
    let httpParams = this._httpParams(params);

    // Modify the body to include studio references
    this._injectStudioReferenceIntoBody(body);

    // Get new token and then send POST request
    return this._csrfToken().pipe(
      switchMap(() => {
        return this.http.post(
          this.baseURL + endpoint,
          body,
          {
            observe: 'response',
            headers: this._httpHeaders,
            params: httpParams,
            withCredentials: true
          }
        ).pipe(
          map(response => response.body) // This line maps the full HTTP response to its body
        );
      })
    );
  }

  /**
   * Standard HTTP PATCH to Drupal.
   *
   * @param endpoint
   * @param body
   * @param params
   * @returns Observable
   */
  httpPATCH(endpoint: string, body: any, params?: { parameter: string, value: string }[]): Observable<any> {
    // Get HTTPParams object
    let httpParams = this._httpParams(params);

    // Modify the body to include studio references
    this._injectStudioReferenceIntoBody(body);

    // Get new token and then send PATCH request
    return this._csrfToken().pipe(
      switchMap(() => {
        return this.http.patch(
          this.baseURL + endpoint,
          body,
          {
            observe: 'response',
            headers: this._httpHeaders,
            params: httpParams,
            withCredentials: true
          }
        ).pipe(
          map(response => response.body) // This line maps the full HTTP response to its body
        );
      })
    );
  }

  /**
   * Standard HTTP DELETE to Drupal.
   *
   * @param endpoint
   * @param body
   * @param params
   * @returns Observable
   */
  httpDELETE(endpoint: string, body?: { parameter: string, value: string }[], params?: { parameter: string, value: string }[]): Observable<any> {
    // Get HTTPParams object
    let httpParams = this._httpParams(params);

    // Modify the body to include studio references
    this._injectStudioReferenceIntoBody(body);

    // Get new token and then send DELETE request
    return this._csrfToken().pipe(
      switchMap(() => {
        return this.http.delete(
          this.baseURL + endpoint,
          {
            observe: 'response',
            headers: this._httpHeaders,
            params: httpParams,
            withCredentials: true
          }
        );
      })
    );
  }

  /**
   * Support for uploading files in Drupal.
   */
  uploadFile(entity_type_id: string, bundle: string, field_name: string, fileName: string, body) {
    let url = `/file/upload/${entity_type_id}/${bundle}/${field_name}`;

    // Get CSRF token and then upload the file
    return this._csrfToken().pipe(
        switchMap(() => {
            let headers = new HttpHeaders({
                'Content-Type': 'application/octet-stream',
                'X-CSRF-Token': this._authService.csrf_token,
                'Content-Disposition': `file; filename="${fileName}"`
            });

            console.log('Uploading file: ', body, headers);
            return this.http.post(this.baseURL + url, body, {
                headers: headers,
                withCredentials: true
            });
        })
    );
}

  /**
   *
   * @param route
   * @param httpParams
   * @returns Observable
   */
  downloadFile(route: string, params) {
    let httpParams = this._httpParams(params);
    return this.http.get<Blob>(this.baseURL + route, {
      headers: this._httpHeaders,
      params: httpParams,
      withCredentials: true,
      observe: 'response',
      responseType: 'blob' as 'json'
    })
  }

  private logError(error: string) {
    // do nothing :(
    console.log(error);
  }


  private _injectStudioReferenceIntoBody(body: any) {
      if (!body) {
        return; // exit early if body is falsy
      }

      // If there are studios, inject the studio reference into the body
      if (this._authService.studios && this._authService.studios.length > 0) {
          const lastStudio = this._authService.studios[this._authService.studios.length - 1];
          body['field_studio_reference_target_id'] = lastStudio.id;
          body['field_studio_reference'] = lastStudio.id;

          // Iterate through the body and find nested entities to inject the studio reference
          for (const key in body) {
              if (body[key] && typeof body[key] === 'object') {
                  this._injectStudioReferenceIntoNestedEntity(body[key]);
              }
          }
      }
  }

  private _injectStudioReferenceIntoNestedEntity(entity: any) {
    if (this.needsStudioReference(entity)) {
      this._authService.studios.forEach(studio => {
        if (studio.id !== undefined && studio.id !== null) {
          entity['field_studio_reference_target_id'] = studio.id;
          entity['field_studio_reference'] = studio.id;
        }
      });
    }

    // Recursive case: Dive deeper into nested objects/arrays
    for (const key in entity) {
      if (entity[key] && typeof entity[key] === 'object') {
        if (Array.isArray(entity[key])) {
          for (const item of entity[key]) {
            this._injectStudioReferenceIntoNestedEntity(item);
          }
        } else {
          this._injectStudioReferenceIntoNestedEntity(entity[key]);
        }
      }
    }
  }

}
