import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { SquareEventService } from 'src/app/services/square-event.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-form-square-settings',
  templateUrl: './form-square-settings.component.html',
  styleUrls: ['./form-square-settings.component.css']
})
export class FormSquareSettingsComponent implements OnInit {
  squareSettingsForm: FormGroup;
  actionType: 'create' | 'edit' = 'create';
  showProgressSpinner = false;
  successMessage: string;
  errorMessage: string;
  squareEnvironments = [
    { value: 'local', label: 'Local' },
    { value: 'sandbox', label: 'Sandbox' },
    { value: 'live', label: 'Live' }
  ];
  isAuthorized = false;
  sandboxApplicationId = 'sandbox-sq0idb-IPaudxf_9T1qRvxBfvZ1hA';
  productionApplicationId = 'sq0idp-CLsi8bA63Dn5Z4PwRpMt1g';
  currentEnvironment: string = environment.name; // Use the environment variable

  constructor(
    private fb: FormBuilder,
    private drupalRESTService: DrupalRESTService,
    private _authService: AuthService,
    private squareEventService: SquareEventService
  ) {}

  ngOnInit(): void {
    this.squareSettingsForm = this.fb.group({
      configurationIsEnabled: [false],
      squareAccessToken: ['', Validators.required],
      squareApplicationId: ['', Validators.required],
      squareApplicationSecret: ['', Validators.required],
      squareEnvironment: ['', Validators.required],
      squareLocationId: ['', Validators.required],
      squareTerminalDeviceId: ['', Validators.required],
      squareOAuthRedirectUri: ['', Validators.required],
      terminalTimeoutDuration: ['', [Validators.required, Validators.min(0)]],
      transactionCurrency: ['', Validators.required]
    });

    this.loadSquareSettings();
  }

  loadSquareSettings() {
    const configFieldName = 'field_square_ref';
    this.drupalRESTService
      .httpGET(`/api_rest/v1/loadStudioConfig?config_field_name=${configFieldName}`)
      .subscribe(
        (response) => {
          const formData = {
            configurationIsEnabled: response?.['field_configuration_is_enabled']?.[0]?.value,
            squareAccessToken: response?.['field_square_access_token']?.[0]?.value,
            squareApplicationId: response?.['field_square_application_id']?.[0]?.value,
            squareApplicationSecret: response?.['field_square_application_secret']?.[0]?.value,
            squareEnvironment: response?.['field_square_environment']?.[0]?.value,
            squareLocationId: response?.['field_square_location_id']?.[0]?.value,
            squareTerminalDeviceId: response?.['field_square_terminal_device_id']?.[0]?.value,
            squareOAuthRedirectUri: response?.['field_square_oauth_redirect_uri']?.[0]?.value,
            terminalTimeoutDuration: response?.['field_terminal_timeout_duration']?.[0]?.value,
            transactionCurrency: response?.['field_transaction_currency']?.[0]?.value
          };

          this.squareSettingsForm.patchValue(formData);
          this.isAuthorized = !!formData.squareAccessToken;

          if (this.isAuthorized) {
            this.hideFieldsOnConnected();
          }
        },
        (error) => {
          console.error('Error loading Square settings:', error);
          this.errorMessage = 'Error loading Square settings.';
        }
      );
  }

  hideFieldsOnConnected() {
    // Hide fields that are not necessary when connected
    this.squareSettingsForm.get('squareApplicationId')?.disable();
    this.squareSettingsForm.get('squareApplicationSecret')?.disable();
    this.squareSettingsForm.get('squareOAuthRedirectUri')?.disable();
  }

  saveSquareSettings() {
    const configFieldName = 'field_square_ref';
    const formValues = this.squareSettingsForm.value;

    const postData = {
      config_field_name: configFieldName,
      config_data: {
        field_configuration_is_enabled: [{ value: formValues.configurationIsEnabled }],
        field_square_access_token: [{ value: formValues.squareAccessToken }],
        field_square_application_id: [{ value: formValues.squareApplicationId }],
        field_square_application_secret: [{ value: formValues.squareApplicationSecret }],
        field_square_environment: [{ value: formValues.squareEnvironment }],
        field_square_location_id: [{ value: formValues.squareLocationId }],
        field_square_terminal_device_id: [{ value: formValues.squareTerminalDeviceId }],
        field_square_oauth_redirect_uri: [{ value: formValues.squareOAuthRedirectUri }],
        field_terminal_timeout_duration: [{ value: formValues.terminalTimeoutDuration }],
        field_transaction_currency: [{ value: formValues.transactionCurrency }]
      }
    };

    this.showProgressSpinner = true;
    this.successMessage = '';
    this.errorMessage = '';

    this.drupalRESTService
      .httpPOST('/api_rest/v1/saveStudioConfig', postData)
      .subscribe(
        (response) => {
          console.log('Square settings saved successfully:', response);
          this.successMessage = 'Square settings saved successfully.';
          this.showProgressSpinner = false;
        },
        (error) => {
          console.error('Error saving Square settings:', error);
          this.errorMessage = 'Error saving Square settings.';
          this.showProgressSpinner = false;
        }
      );
  }

  onSubmit() {
    this.saveSquareSettings();
  }

  onUpdate() {
    this.saveSquareSettings();
  }

  authorizeSquare() {
    const redirectUri = `${environment.drupalUrl}/square/oauth/callback`;
    const scopes = ['PAYMENTS_READ', 'PAYMENTS_WRITE', 'ORDERS_READ', 'ORDERS_WRITE', 'MERCHANT_PROFILE_READ', 'DEVICES_READ'];
    const randomState = this.generateRandomState();
    const studioId = this._authService.studios?.[0]?.id;
    const state = btoa(JSON.stringify({ random: randomState, studioId: studioId }));

    this.drupalRESTService.httpPOST('/api_rest/v1/store-oauth-state', { state }).subscribe(
      () => {
        let authUrl = '';
        let applicationId = '';
        if (environment.name === 'local') {
          authUrl = 'https://connect.squareupsandbox.com/oauth2/authorize';
          applicationId = this.sandboxApplicationId;
        } else {
          authUrl = 'https://connect.squareup.com/oauth2/authorize';
          applicationId = this.productionApplicationId;
        }

        const url = `${authUrl}?client_id=${encodeURIComponent(applicationId)}&response_type=code&scope=${scopes.join('+')}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${encodeURIComponent(state)}`;

        console.log('Redirecting to Square OAuth URL:', url);
        setTimeout(() => {
          window.open(url, '_self')
        }, 1000);
      },
      (error) => {
        console.error('Failed to store OAuth state:', error);
        this.errorMessage = 'Failed to initiate Square authorization. Please try again.';
      }
    );
  }

  deauthorizeSquare() {
    this.drupalRESTService
      .httpPOST('/api_rest/v1/deauthorizeSquare', {})
      .subscribe(
        (response) => {
          console.log('Square deauthorized successfully:', response);
          this.successMessage = 'Square deauthorized successfully.';
          this.isAuthorized = false;
          this.squareSettingsForm.patchValue({ squareAccessToken: '' });
          this.showAllFields();

          // Notify other components
          this.squareEventService.triggerDeauthorizeSquare();
        },
        (error) => {
          console.error('Error deauthorizing Square:', error);
          this.errorMessage = 'Error deauthorizing Square.';
        }
      );
  }

  showAllFields() {
    this.squareSettingsForm.get('squareApplicationId')?.enable();
    this.squareSettingsForm.get('squareApplicationSecret')?.enable();
    this.squareSettingsForm.get('squareOAuthRedirectUri')?.enable();
  }

  private generateRandomState(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }

  setCurrentEnvironment() {
    const environmentValue = this.squareSettingsForm.get('squareEnvironment')?.value;
    switch (environmentValue) {
      case 'local':
        this.currentEnvironment = 'Local';
        break;
      case 'sandbox':
        this.currentEnvironment = 'Sandbox';
        break;
      case 'live':
        this.currentEnvironment = 'Live';
        break;
      default:
        this.currentEnvironment = 'Unknown';
        break;
    }
  }
}
