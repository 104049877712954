<app-entity-mat-dialog-title [title]="'Update Lesson ' + updateLessonTitle">>
</app-entity-mat-dialog-title>

<div mat-dialog-content>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        [aria-label]="checkboxLabel()">
      </mat-checkbox>
    </th>
    <td mat-cell *matCellDef="let row">
      <mat-checkbox (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)">
      </mat-checkbox>
    </td>
  </ng-container>


  <!-- title Column -->
  <ng-container matColumnDef="title">
    <th mat-header-cell *matHeaderCellDef> Student </th>
    <td mat-cell *matCellDef="let element">{{ element?.studentDetails?.studentName }} ({{element?.studentDetails?.studentAccountId}})</td>
  </ng-container>


  <ng-container matColumnDef="lesson_type">
    <th mat-header-cell *matHeaderCellDef> Student </th>
    <td mat-cell *matCellDef="let element">{{ element?.studentDetails?.studentName }}  {{element | json}} </td>
  </ng-container>


  <!-- instructor Column -->
  <ng-container matColumnDef="instructor">
    <th mat-header-cell *matHeaderCellDef> Instructor </th>
    <td mat-cell *matCellDef="let element"> {{element.instructorName}} </td>
  </ng-container>


  <!-- time Column -->
  <ng-container matColumnDef="time">
    <th mat-header-cell *matHeaderCellDef> Time </th>
    <td mat-cell *matCellDef="let element"> {{ element.start | date:'hh:mm' }} -
      {{element.end | date:'hh:mm'}} </td>
  </ng-container>


  <!-- status Column -->
  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef> Status </th>
    <td mat-cell *matCellDef="let element"> {{element.status}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"
    (click)="selection.toggle(row)">
  </tr>
</table>

<ng-container *ngIf="this.dataSource?.['data'] && this.dataSource.data == 0">
    <div>
        No event lessons are loaded in the calendar.
    </div>
</ng-container>

<app-error-message [message]="this.errorMessage"></app-error-message>

</div>

<mat-dialog-actions>
  <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="form" style="width:100%">
    <div class="container">
      <div class="row">
        <!-- Status Dropdown -->
        <div class="col-6">
          <mat-form-field class="full-width-field">
            <mat-label>Status</mat-label>
            <mat-select required ngModel name="lessons_status_selectbox">
              <mat-option
                *ngFor="let element of this._taxonomyService.lesson_status"
                [value]="element.id">
                {{element.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col-6">
          <button style="float: right;width: 100%;" type="button" [disabled]="!f.valid || !(this.selection.selected.length > 0)" (click)="onSubmit(f);" mat-raised-button
            color="accent">Update Lessons</button>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-actions>
