import { SelectionModel } from '@angular/cdk/collections';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { BaseSimpleDialogComponent } from '../base-simple-dialog/base-simple-dialog.component';
import moment, { Moment } from 'moment';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-update-lesson',
  templateUrl: './update-lesson.component.html',
  styleUrls: ['./update-lesson.component.css']
})
export class UpdateLessonComponent extends BaseSimpleDialogComponent {

  displayedColumns: string[] = ['select', 'title', 'instructor', 'time', 'status'];
  @ViewChild(NgForm) override f: NgForm;

  updateLessonTitle: string = "";

  override onSubmit(form: NgForm) {

    let type = 'lesson';
    let dateTime = moment().format('YYYY-MM-DD[T]hh:mm:ss');

    console.log('form form value', form.form.value)
    console.log('selection', this.selection['_selected'])

    // Build request...
    let body:any = {}
    let events = [];

    // Grab selected ID's from selection.
    for (const property in this.selection['_selected']) {
      events[property] = this.selection['_selected'][property].id
      body = {
        ...body,
        events
      }
    }

    // Add status, and other various params the endpoint is expecting.
    body = {
      ...body,
      'status_id': form.form.value.lessons_status_selectbox,
      'type': type,
      'dateTime': dateTime,
    };

    this._drupalRESTService.httpPOST('/api_rest/v1/updateLesson', body, [{ parameter: '_format', value :'json' }])
      .subscribe(data => {
        if (data['success']) {
          this.closeDialog();
        }
        if (data['message']) {
          this.errorMessage = data['message'];
        }
      },
      error => {
        this.handleError(error);
      });

  }

  ngOnInit(): void {
    console.log('injectedDialogData-eventData', this._injectedDialogData?.['eventData'])

    this.updateLessonTitle = " - " + moment(this._injectedDialogData?.['eventData']?.[0]?.start).format('dddd, MMMM DD, YYYY');
    const eventData = this._injectedDialogData?.['eventData']?.[0];
    const eventStartDate = eventData?.start;

    // Check if eventStartDate exists and then format it.
    if (eventStartDate) {
      const formattedDate = moment(eventStartDate).format('dddd, MMMM DD, YYYY');
      this.updateLessonTitle = ` - ${formattedDate}`;
    } else {
      // Handle case where event start date might not be available.
      this.updateLessonTitle = "";
    }

    this.dataSource = new MatTableDataSource(
      this.filterEventLessonType(this._injectedDialogData['eventData'], 'lesson')
    );
  }
}
