import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EntityRESTService } from 'src/app/services/entity-rest.service';
import { UserService } from 'src/app/services/user.service';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { FieldsService } from 'src/app/services/fields.service';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-frm-manage-staff',
  templateUrl: './frm-manage-staff.component.html',
  styleUrls: ['./frm-manage-staff.component.css']
})
export class FrmManageStaffComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public _injectedDialogData: any,
    public _entityRESTService: EntityRESTService,
    public _userRESTService: UserService,
    private dialogRef: MatDialogRef<FrmManageStaffComponent>,
    private _drupalRESTService: DrupalRESTService,
    private _fieldsService: FieldsService,
    public _permissionsService: PermissionsService,
  ) {}

  myForm: FormGroup;
  errorMessage: string;
  successMessage: string;
  eckType = "user";
  bundle = "user";
  entityId = this._injectedDialogData?.['data']?.uid;
  showProgressSpinner: boolean = false;
  actionType: string;
  defaultTime: string = '00:00:00';
  formIsPatching: boolean = false; // Initialize the formIsPatching flag
  availableRoles: string[] = ['Executive', 'Instructor'];

  ngOnInit(): void {
    console.log(this._injectedDialogData?.['data']);
    this.eckType = "user";
    this.bundle = "user";
    this.entityId = this._injectedDialogData?.['data']?.uid;

    this.myForm = new FormGroup({
      uid: new FormControl(''),
      mail: new FormControl('', [Validators.required, Validators.email]),
      field_last_name: new FormControl('', Validators.required),
      field_first_name: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      pass: new FormControl('', this.actionType === 'create' ? [] : []),
      confirmPassword: new FormControl(''),
      field_ami_id: new FormControl(''),
      field_birthday: new FormControl(''),
      field_hiring_date: new FormControl(''),
      field_cell_number: new FormControl(''),
      roles: new FormControl(['Executive', 'Instructor']),
      status: new FormControl(false),
    }, { validators: this.passwordsMatchingValidator });

    this.initializeDialogData();

    let previousFirstName = '';
    let previousLastName = '';

    // Subscribe to form value changes with conditions
    this.myForm.valueChanges.pipe(
      debounceTime(1000), // Wait for 1 second of inactivity
      distinctUntilChanged(), // Only emit if the value has changed
      filter(() => {
        const firstName = this.myForm.get('field_first_name').value;
        const lastName = this.myForm.get('field_last_name').value;
        const hasChanged = firstName !== previousFirstName || lastName !== previousLastName;
        previousFirstName = firstName;
        previousLastName = lastName;
        return hasChanged && firstName && lastName;
      })
    ).subscribe(() => {
      this.OR_lookupStaff();
    });
  }

  initializeDialogData() {
    const dialogData = this._injectedDialogData?.data;
    if (dialogData) {
      if (dialogData.action) {
        this.actionType = dialogData.action;
      }
      if (dialogData.uid && this.actionType === 'edit') {
        this.initializeForEdit(dialogData.uid);
      }
    }
  }

  onSubmit() {
    if (this.myForm.valid) {
      // Convert status to Active or Blocked.
      this.myForm.get('status').setValue(this.myForm.get('status').value ? 'Active' : 'Blocked');

      this._userRESTService.createUser(this.myForm.value).subscribe(
        response => {
          this.myForm.reset();
          this.dialogRef.close();

          // Refresh instructors and executives
          this._fieldsService.fetchAndSetInstructorData().then(data => {
          }).catch(error => {
          });
        },
        error => {
          if (error.error && error.error.message) {
            this.errorMessage = error.error.message;
          } else {
            this.errorMessage = "Failed to create user due to an unknown error.";
          }
        }
      );
    }
  }

  initializeForEdit(userId: number) {
    this._entityRESTService.getEntity(this.eckType, this.bundle, userId).subscribe(
      response => {
        // Remove the password field from the response.
        delete response?.['pass'];
        // Convert 'status' to boolean.
        console.log('status', response['status']);
        response['status'] = response['status'] === '1' ? true : false;
        // Ensure roles is an array.
        response['roles'] = Array.isArray(response['roles']) ? response['roles'] : [response['roles']];
        // Capitalize the roles for display in the template
        response['roles'] = response['roles'].map((role: string) => role.charAt(0).toUpperCase() + role.slice(1));

        this.myForm.patchValue(response);
      },
      error => {
        this.errorMessage = "Failed to load user: " + error.message; // Set error message
      }
    );
  }

  onUpdate() {
    if (this.myForm.valid) {
      const userId = this.myForm.get('uid').value;
      const updateData = this.myForm.value;

      if (!updateData.pass) {
        delete updateData.pass;
      }

      this._userRESTService.updateUser(userId, updateData).subscribe(
        response => {
          // Handle successful update
          this.successMessage = response.message; // Set success message from response
          // Optionally, you can reset the form or close the dialog here
          // this.myForm.reset();
          // this.dialogRef.close();

          // Refresh instructors and executives
          this._fieldsService.fetchAndSetInstructorData().then(data => {
          }).catch(error => {
          });
        },
        error => {
          this.errorMessage = "Failed to update user: " + error.message; // Set error message
        }
      );
    }
  }

  onDelete(userId: number) {
    this._userRESTService.deleteUser(userId).subscribe(
      response => {
        // Handle successful deletion
      },
      error => {
        this.errorMessage = "Failed to delete user: " + error.message; // Set error message
      }
    );
  }

  OR_lookupStaff() {
    // this.showProgressSpinner = true;
    this.errorMessage = ''; // Clear any previous error message

    // Extract first name and last name from the form
    const firstName = this.myForm.get('field_first_name').value;
    const lastName = this.myForm.get('field_last_name').value;

    // Check if both first name and last name are provided
    if (!firstName || !lastName) {
      // Optionally, set an error message if needed
      this.errorMessage = 'Please enter both first name and last name to search.';
      return;
    }

    // If an existing field_ami_id is present, don't search for staff.
    if (this.myForm.get('field_ami_id').value) {
      return;
    }

    // Prepare parameters for the GET request
    const params = [
      { parameter: 'field_first_name', value: firstName },
      { parameter: 'field_last_name', value: lastName }
    ];

    this._drupalRESTService.httpGET('/api_rest/v1/OR_lookupStaff', params).subscribe(
      (response: any[]) => { // Specify the type as an array of any objects
        this.showProgressSpinner = false;

        // Handle successful response
        if (response && response.length > 0) {
          const staffData = response[0];

          this.formIsPatching = true; // Disable value changes subscription
          // Update the form with the returned data
          const patchValue: any = {
            field_ami_id: staffData.studios?.[0]?.id || '',
          };

          // Only patch field_cell_number if it's not already filled out
          if (!this.myForm.get('field_cell_number').value) {
            // Do not patch phone number.
            // patchValue.field_cell_number = staffData.studios?.[0]?.phone || '';
          }

          // Only patch mail if it's not already filled out
          if (!this.myForm.get('mail').value) {
            patchValue.mail = staffData.email || '';
          }

          this.myForm.patchValue(patchValue);
          this.formIsPatching = false; // Re-enable value changes subscription
        } else {
          // this.errorMessage = "No staff found with the given details.";
          // Clear out AMI ID and cell number if no staff found.
          this.myForm.patchValue({
            field_ami_id: '',
            field_cell_number: '',
          });
        }
      },
      error => {
        this.showProgressSpinner = false;
        this.errorMessage = "Failed to search for staff: " + error.message; // Set error message
      }
    );
  }

  passwordsMatchingValidator(formGroup: FormGroup): { [s: string]: boolean } | null {
    const password = formGroup.get('pass').value;
    const confirmPassword = formGroup.get('confirmPassword').value;
    if (password !== confirmPassword) {
      return { 'passwordMismatch': true };
    }
    return null;
  }
}
