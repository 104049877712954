<div *ngIf="actionType === 'edit'; else createTitle">
    <app-entity-mat-dialog-title title="Edit Staff Member"></app-entity-mat-dialog-title>
  </div>
  <ng-template #createTitle>
    <app-entity-mat-dialog-title title="Add Staff Member"></app-entity-mat-dialog-title>
  </ng-template>
  <div [ngClass]="{ 'loading-shade': showProgressSpinner }">
    <div mat-dialog-content class="mat-tab-header">
      <mat-progress-spinner class="matProgressSpinnerCentered" *ngIf="showProgressSpinner" mode="indeterminate"></mat-progress-spinner>
      <form class="col-12" [formGroup]="myForm">
        <app-success-message [message]=this.successMessage></app-success-message>
        <app-error-message [message]=this.errorMessage></app-error-message>
        <div class="d-none">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>uid</mat-label>
            <input matInput formControlName="uid">
          </mat-form-field>
        </div>
        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput formControlName="mail">
          </mat-form-field>
        </div>
        <div class="row">
          <div class="col-6">
            <mat-form-field class="full-width-field" appearance="outline">
              <mat-label>First Name</mat-label>
              <input matInput formControlName="field_first_name">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="full-width-field" appearance="outline">
              <mat-label>Last Name</mat-label>
              <input matInput formControlName="field_last_name">
            </mat-form-field>
          </div>
        </div>
        <div class="col-12">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>User Name</mat-label>
            <input [readonly]="actionType === 'edit'" matInput formControlName="name">
          </mat-form-field>
        </div>
        <div class="row" *ngIf="actionType === 'edit'">
          <div class="col-6">
            <mat-form-field class="full-width-field" appearance="outline">
              <mat-label>Password</mat-label>
              <input matInput type="password" formControlName="pass">
            </mat-form-field>
          </div>
          <div class="col-6">
            <mat-form-field class="full-width-field" appearance="outline">
              <mat-label>Confirm Password</mat-label>
              <input matInput type="password" formControlName="confirmPassword">
              <mat-error *ngIf="myForm.get('confirmPassword').invalid && (myForm.get('confirmPassword').dirty || myForm.get('confirmPassword').touched)">
                <span *ngIf="myForm.get('confirmPassword').errors?.['required']">Confirmation password is required.</span>
                <span *ngIf="myForm.get('confirmPassword').errors?.['passwordMismatch']">Passwords do not match.</span>
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12" [ngClass]="{'d-none': !this._permissionsService.has_access('view_admin_links')}">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>AMI ID</mat-label>
            <input matInput formControlName="field_ami_id">
          </mat-form-field>
        </div>
        <!-- <div class="row">
            <div class="col-6">
                <mat-form-field>
                  <input formControlName="field_birthday" name="field_birthday" matInput [ngxMatDatetimePicker]="picker_field_birthday" placeholder="Birthday">
                  <mat-datepicker-toggle matSuffix [for]="picker_field_birthday"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #picker_field_birthday [showSeconds]="false" [defaultTime]="defaultTime" [hideTime]="true"></ngx-mat-datetime-picker>
                </mat-form-field>
              </div>
              <div class="col-6">
                <mat-form-field>
                  <input formControlName="field_hiring_date" name="field_hiring_date" matInput [ngxMatDatetimePicker]="picker_field_hiring_date" placeholder="Hiring Date">
                  <mat-datepicker-toggle matSuffix [for]="picker_field_hiring_date"></mat-datepicker-toggle>
                  <ngx-mat-datetime-picker #picker_field_hiring_date [showSeconds]="false" [defaultTime]="defaultTime" [hideTime]="true"></ngx-mat-datetime-picker>
                </mat-form-field>
              </div>
        </div> -->

        <div class="row">
        <div class="col-6">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Cell Number</mat-label>
            <input appPhoneNumberFormatter matInput formControlName="field_cell_number">
          </mat-form-field>
        </div>


        <div class="col-6">
          <mat-form-field class="full-width-field" appearance="outline">
            <mat-label>Roles</mat-label>
            <mat-select formControlName="roles" multiple>
              <mat-option *ngFor="let role of availableRoles" [value]="role">{{role}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        </div>

        <div class="row">
        <div class="col-6 mb-3">
          <mat-checkbox formControlName="status">Active</mat-checkbox>
        </div>
        </div>
      </form>
    </div>
  </div>
  <mat-dialog-actions align="end">
    <button type="button" [disabled]="!myForm.valid" (click)="actionType === 'create' ? onSubmit() : onUpdate()" mat-raised-button color="accent">
      {{ actionType === 'create' ? 'Save' : 'Update' }}
    </button>
  </mat-dialog-actions>
