import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DrupalRESTService } from 'src/app/services/drupal-rest.service';
import { NgForm } from '@angular/forms';
import { TaxonomyService } from 'src/app/services/taxonomy.service';
import { Router } from '@angular/router';
import { IDatePickerConfig } from 'ng2-date-picker';
import * as moment from 'moment';
import { BaseComponent } from '../../forms/base-form/base-form.component';

@Component({
  selector: 'app-day-view-frm-filter',
  templateUrl: './day-view-frm-filter.component.html',
  styleUrls: [
    './day-view-frm-filter.component.css',
    '../../../app.component.css'
  ]
})
export class DayViewFrmFilterComponent extends BaseComponent implements OnInit {

  ngOnInit(): void {
  }

  config: IDatePickerConfig = {
    showWeekNumbers: true,
    // other configurations...
  };

  @Output() updateCalendarEvents: EventEmitter<string> = new EventEmitter();

  // Filter configuration
  @Input() filterInstructorCategory;
  @Input() filterLessonType: string = "";
  @Input() filterInstructor: string = "";
  @Input() filterStudent: string = "";
  @Input() filterDate: string = "";
  @Input() filterGroupLesson: boolean = false;
  @Input() filterLesson: boolean = false;
  @Input() filterServices: boolean = false;

  @Input() frmFilterData: {}[];

  filterInstructorCategoryVal;
  filterLessonTypeVal;
  filterInstructorVal;
  filterStudentVal;
  filterDateVal;
  filterGroupLessonVal: boolean;
  filterLessonVal: boolean;
  filterServicesVal: boolean;

  // Convert the ID value to the human readable version for output.
  getNameFromId(index, Obj, key = 'value') {
    let item = Obj.find((element) => {
      return element.id == index || element.uid == index;
    });
    return item?.[key];
  }

  formatDate(date: any): string {
    // Ensure date is parsed and formatted correctly using moment
    return moment(date, 'YYYY-MM-DD').format('dddd, M/D/YYYY, h:mm A');
  }

  // Filter Form Submit
  override onSubmit(form: NgForm) {
    if (this.filterDate) {
      const formattedDate = this.formatDate(this.filterDate);
      console.log('Formatted date:', formattedDate);

      // Update the form value with the formatted date
      form.value.filterDate = formattedDate;

      // Call parent function to update calendar
      this.updateCalendarEvents.emit(form.value);
    } else {
      console.error('Invalid date:', this.filterDate);
    }
  }

  autoCompleteOptions: any = [];

  // Handle the automplete for student field.
  onStudentInput($event: { target: { value: string; }; }) {

    let term: string = $event.target.value;
    let eckType: string = "student_accounts"; // student_accounts
    let bundle: string = "student_account"; // student_account
    let field: string = "title";

    // Build the endpoint.
    let endpoint: string = "/api_rest/v1/autocomplete?";
    endpoint = endpoint + "term=" + term;
    endpoint = endpoint + "&eckType=" + eckType;
    endpoint = endpoint + "&bundle=" + bundle;
    endpoint = endpoint + "&field=" + field;

    console.log($event.target.value);
    console.log(endpoint);

    // Do the request.
    this._drupalRESTService.httpGET(endpoint)
    .subscribe(data => {
      console.log(data);

      this.autoCompleteOptions = data;
    })

    // this.drupalRESTService.httpGET(endpoint);
  }
}
