import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  current_user: Object = {};
  csrf_token: string = "";
  logout_token: string = "";
  user_id: number;
  userAuthenticated: boolean = false;
  studios: any[] = [];
  userAgreedToAgreement: boolean = false;

  is_user_authenticated() {
    return this.userAuthenticated;
  }

  set_user_authenticated(boolean: boolean) {
    this.userAuthenticated = boolean;
  }

  has_user_agreed_to_agreement() {
    return this.userAgreedToAgreement;
  }

  set_user_agreed_to_agreement(agreed: boolean) {
    this.userAgreedToAgreement = agreed;
  }
}
